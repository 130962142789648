function email(data) {
  const message = document.querySelector('.status-message')

  fetch("send.php", {
    method: "POST",
    body: data,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
    .then(response => response.text())
    .then(response => {
      if (response === 'ok') {
        message.innerHTML = 'Вашя заявка успешно отправлена!';
      } else {
        message.innerHTML = 'При отправке заявки возникла ошибка!';
      }
    });
}

const initFormValidate = () => {
  const formEls = document.querySelectorAll('[data-form-validate]');

  if (!formEls.length) {
    return;
  }

  formEls.forEach((formEl) => {
    const pristine = new window.Pristine(formEl, {
      classTo: 'form-field',
      errorClass: 'form-field--error',
      successClass: 'form-field--success',
      errorTextParent: 'form-field',
      errorTextTag: 'div',
      errorTextClass: 'form-field__error',
    }, true);

    formEl.addEventListener('submit', (e) => {
      e.preventDefault();

      const valid = pristine.validate();

      if (valid) {
        const formData = new FormData(formEl);
        email(formData);
      }
    });
  });
};

export {initFormValidate};
