const initIntro = () => {
  const introEl = document.querySelector('.intro');

  if (!introEl) {
    return;
  }

  const btnEls = introEl.querySelectorAll('.intro__btn');

  btnEls.forEach((btnEl) => {
    btnEl.addEventListener('click', (e) => {
      e.preventDefault();

      const targetId = btnEl.getAttribute('href');
      const targetEl = document.querySelector(targetId);

      if (targetEl) {
        targetEl.scrollIntoView({
          behavior: 'smooth',
        });
      }
    });
  });
};

export {initIntro};
